var _a;
import { PLATFORM } from "../../../common/constants";
import { hasOnlyQCPermissions } from "../../rbac/permissions";
import { GA_COMMAND, GA_EVENT } from "./constants";
import { GA_PLATFORM } from "./types";
var GA_TAG = (_a = process.env.GA_TAG) !== null && _a !== void 0 ? _a : "";
/* istanbul ignore next */ // GA TAG is removed. This is kept here as placeholder
var gtag = function () {
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        params[_i] = arguments[_i];
    }
    // DO NOTHING
};
/* istanbul ignore next */ // GA TAG code, no point testing
export var setupGAConfig = function (config) {
    gtag(GA_COMMAND.CONFIG, GA_TAG, config);
};
/* istanbul ignore next */ // GA TAG code, no point testing
export var trackGARouteEvent = function (_a) {
    var route = _a.route;
    gtag(GA_COMMAND.EVENT, GA_EVENT.PAGE_VIEW, { page_location: route });
};
export var getGAPlatform = function (platform, permissions) {
    if (hasOnlyQCPermissions(permissions)) {
        return platform === PLATFORM.PARTNER_CENTER
            ? GA_PLATFORM.QC_PC
            : GA_PLATFORM.QC_AP;
    }
    return platform === PLATFORM.PARTNER_CENTER
        ? GA_PLATFORM.FS_PC
        : GA_PLATFORM.FS_AP;
};
/* istanbul ignore next */ // GA TAG code, no point testing
export var trackLoginEvent = function (_a) {
    var platform = _a.platform, permissions = _a.permissions;
    gtag(GA_COMMAND.EVENT, GA_EVENT.LOGIN, {
        platform: getGAPlatform(platform, permissions),
    });
};
