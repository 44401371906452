import { stringEnumsToArray } from "../../../../common/stringutils";
import { PLATFORM_ACCESS } from "../../../../components/context/constants";
import { makeContext } from "../../../../components/context/helpers";
export var initialUserData = {
    email: "",
    name: "",
    contact_no: "",
    platform_access: PLATFORM_ACCESS.ADMIN_PANEL,
    user_type: "",
    roles: [],
    fs_assignments: {
        brands: [],
        businesses: [],
        locations: [],
    },
    qcAssignments: {
        brands: [],
        locations: [],
    },
    brandAll: false,
    locationAll: false,
    kpos: {
        passcode: "",
        confirmpasscode: "",
        assignments: [],
    },
};
export var initialUserAddContextData = {
    add: initialUserData,
};
export var UserAddContextProvider = makeContext(initialUserAddContextData);
export var KPOS_PPASSCODE_LENGTH;
(function (KPOS_PPASSCODE_LENGTH) {
    KPOS_PPASSCODE_LENGTH[KPOS_PPASSCODE_LENGTH["MIN"] = 6] = "MIN";
    KPOS_PPASSCODE_LENGTH[KPOS_PPASSCODE_LENGTH["MAX"] = 8] = "MAX";
})(KPOS_PPASSCODE_LENGTH || (KPOS_PPASSCODE_LENGTH = {}));
export var TENANT_ROLE;
(function (TENANT_ROLE) {
    TENANT_ROLE["FS_TENANT_ANALYST"] = "fs_tenant_analyst";
})(TENANT_ROLE || (TENANT_ROLE = {}));
export var TENANT_ROLES = stringEnumsToArray(TENANT_ROLE);
