import { useRoutesFeatureFlagCheck } from "../../boot/hooks";
import { useUpdateDatadogSessionUser } from "../../datadog/useUpdateDatadogSessionUser";
import { useUserGASetup } from "../../tracking/ga/hooks/useUserGASetup";
import { useCheckLatestBuild } from "../../versioncheck/useCheckLatestBuild";
import { useUserAccessiblesFiltering } from "../filtering/userAccessibles";
import { useAllowAllFSAccessiblesCheck, useBackofficeUserInfo, useFSConfigurations, useQCInitialData, useQCUserData, } from "./hooks";
import { useUserLastLogonApi } from "./useUserLastLogonApi";
var ApiHandler = function () {
    useUserLastLogonApi();
    useBackofficeUserInfo();
    useAllowAllFSAccessiblesCheck();
    useUserAccessiblesFiltering();
    useFSConfigurations();
    useUserGASetup();
    useRoutesFeatureFlagCheck();
    useQCInitialData();
    useQCUserData();
    useCheckLatestBuild();
    useUpdateDatadogSessionUser();
    return null;
};
export default ApiHandler;
