import { useCallback, useContext, useEffect, useMemo } from "react";
import { globalEvents } from "../../common/GlobalEvents";
import { initAuth } from "../../pages/auth/AuthBoot";
import { initDashboard } from "../../pages/dashboard/DashboardBoot";
import { initFSCatalogue } from "../../pages/fs/catalogue/CatalogueBoot";
import { initFSCompanyManagement } from "../../pages/fs/companies/CompaniesBoot";
import { initDocumentsManagement } from "../../pages/fs/documents/DocumentsBoot";
import { useDocumentsNewItems } from "../../pages/fs/documents/newitemsalert/useDocumentsNewItems";
import { initFPGEVoucher } from "../../pages/fs/fpgevoucher/FPGEVoucherBoot";
import { initFSOperations } from "../../pages/fs/operations/OperationsBoot";
import { initFSOutlets } from "../../pages/fs/outlets/OutletsBoot";
import { initFSPromotions } from "../../pages/fs/promotions/PromotionsBoot";
import { initFSReports } from "../../pages/fs/reports/ReportsBoot";
import { initTransactionsManagement } from "../../pages/fs/transactions/TransactionsBoot";
import { initHelp } from "../../pages/help/HelpBoot";
import { initQCBrandsRestaurantsLocations } from "../../pages/qc/brands-restaurants-locations/BrandsRestaurantsLocationsBoot";
import { initOnlineCatalogs } from "../../pages/qc/OnlineCatalogsBoot";
import { initQCReports } from "../../pages/qc/reports/ReportsBoot";
import { initStaticPage } from "../../pages/staticpage/StaticPageBoot";
import { initSuperAdmin } from "../../pages/superadmin/SuperAdminBoot";
import { initUserManagement } from "../../pages/usermanagement/UserManagementBoot";
import { AllContext } from "../context/constants";
import { useFSBooleanFlag } from "../context/fs/useFSBooleanFlag";
import { usePlatform } from "../context/hooks";
import { useIsLocalDevelopment } from "../rbac/hooks/useIsLocalDevelopment";
import { ROUTE_EVENTS } from "../routing/constants";
import { flattenRoutes, joinRoutes, routeToRouteListing, } from "../routing/helpers";
import { checkRoute } from "./helpers";
export var useRoutesStrapper = function () {
    var _a = useRoutes(), routesContext = _a.routesContext, registerRoute = _a.registerRoute;
    useRoutesListings(routesContext);
    useEffect(function () {
        globalEvents.on(ROUTE_EVENTS.REGISTER, registerRoute);
        return function () {
            globalEvents.off(ROUTE_EVENTS.REGISTER, registerRoute);
        };
    }, [registerRoute]);
};
export var useRoutes = function () {
    var _a = useContext(AllContext.RoutesContext), routesContext = _a[0], setRoutesContext = _a[1];
    var registerRoute = useCallback(function (insertRoutes) {
        setRoutesContext(function (prev) { return joinRoutes(prev, insertRoutes); });
    }, [setRoutesContext]);
    return {
        routesContext: routesContext,
        registerRoute: registerRoute,
    };
};
var useRoutesListings = function (routesContext) {
    var _a = useContext(AllContext.RoutesListingContext), setRoutesListingContext = _a[1];
    var isLocal = useIsLocalDevelopment();
    var platform = usePlatform();
    var routelistings = useMemo(function () { return routeToRouteListing(flattenRoutes(routesContext), platform, isLocal); }, [routesContext, platform, isLocal]);
    useEffect(function () {
        setRoutesListingContext(routelistings);
    }, [routelistings]);
};
export var useModuleStrapper = function () {
    useEffect(function () {
        // order of inits determines the order of the top level menu items
        initAuth();
        initDashboard();
        initStaticPage();
        initSuperAdmin();
        initUserManagement();
        initFSReports();
        initFSOperations();
        initFSCatalogue();
        initFSOutlets();
        initFSPromotions();
        initFSCompanyManagement();
        initTransactionsManagement();
        initDocumentsManagement();
        initFPGEVoucher();
        initQCReports();
        initOnlineCatalogs();
        initQCBrandsRestaurantsLocations();
        initHelp();
    }, []);
    useDocumentsNewItems();
};
export var useRoutesFeatureFlagCheck = function () {
    var _a = useContext(AllContext.RoutesContext), setRoutesContext = _a[1];
    var flagChecker = useFSBooleanFlag();
    useEffect(function () {
        setRoutesContext(function (prev) {
            return prev.map(function (route) { return checkRoute(route, flagChecker); });
        });
    }, [flagChecker]);
};
