export var FPGEVOUCHER_PATH;
(function (FPGEVOUCHER_PATH) {
    FPGEVOUCHER_PATH["FPGEVOUCHER_LIST"] = "/fpgevouchers/list";
    FPGEVOUCHER_PATH["FPGEVOUCHER_ADD"] = "/fpgevouchers/add";
    FPGEVOUCHER_PATH["FPGEVOUCHER_DETAIL"] = "/fpgevouchers/detail";
    // anchor - for code generator
})(FPGEVOUCHER_PATH || (FPGEVOUCHER_PATH = {}));
export var DATE_SEPARATOR = "-";
export var DATE_FORMAT = "DD".concat(DATE_SEPARATOR, "MM").concat(DATE_SEPARATOR, "YYYY");
export var API_DATE_FORMAT = "DD-MM-YYYY";
