import moment from "moment";
export var COLORS;
(function (COLORS) {
    COLORS["FPBLUE50"] = "#e8f0fd";
    COLORS["FPBLUE100"] = "#a3c2f5";
    COLORS["FPBLUE500"] = "#1557bf";
    COLORS["FPORANGE500"] = "#ea6100";
    COLORS["FPORANGE600"] = "#994000";
    COLORS["SNOWWHITE"] = "#ffffff";
    COLORS["SHIRAZ500"] = "#c1083a";
    COLORS["GREENPEA500"] = "#267348";
    COLORS["APRICOT100"] = "#fdbb7e";
    COLORS["COBALT500"] = "#51a0fd";
    COLORS["EMERALD500"] = "#2acca1";
    COLORS["FOREST500"] = "#6fc04b";
    COLORS["CANARY100"] = "#f9e7b3";
    COLORS["CANARY500"] = "#fdc944";
    COLORS["LEATHER500"] = "#d98952";
    COLORS["CORAL100"] = "#ffd7de";
    COLORS["CORAL500"] = "#ef777e";
    COLORS["ROSE100"] = "#f4b5d3";
    COLORS["ROSE500"] = "#e675ab";
    COLORS["PLUM500"] = "#d775df";
    COLORS["BERRY500"] = "#a672fb";
    COLORS["IRIS100"] = "#bbc1fb";
    COLORS["IRIS500"] = "#8893f5";
    COLORS["CERULEAN100"] = "#8fe0f2";
    COLORS["FOUNDATION"] = "#f4f5f7";
    COLORS["OFFWHITE"] = "#fcfcfc";
    COLORS["MINESHAFT700"] = "#333333";
    COLORS["MINESHAFT300"] = "#999999";
    COLORS["MINESHAFT100"] = "#cccccc";
    COLORS["BRIGHTGRAY"] = "#eaeaea";
    COLORS["DARKGRAY"] = "#2B3238";
    COLORS["BLUELINK"] = "#0071c2";
    COLORS["LIGHTRED"] = "#f8e9e9";
    COLORS["BLACK"] = "#000000";
    COLORS["SIDE_NAV_BG"] = "#F4F7F9";
    COLORS["SIDE_NAV_BORDER"] = "#d3dae6";
})(COLORS || (COLORS = {}));
export var FONT;
(function (FONT) {
    FONT["FONT_SIZE_L"] = "20px";
    FONT["FONT_SIZE_M"] = "18px";
    FONT["FONT_SIZE_S"] = "14px";
    FONT["FONT_SIZE_XS"] = "10px";
    FONT["FONT_SIZE_PRINT"] = "0.5em";
    FONT["FONT_SIZE_H1"] = "26px";
    FONT["FONT_SIZE_TITLE"] = "24px";
    FONT["LINE_HEIGHT_XS"] = "12px";
    FONT["LINE_HEIGHT_S"] = "14px";
    FONT["LINE_HEIGHT_M"] = "18px";
    FONT["LINE_HEIGHT_L"] = "28px";
    FONT["LINE_HEIGHT_TITLE"] = "32px";
})(FONT || (FONT = {}));
export var PLATFORM;
(function (PLATFORM) {
    PLATFORM["PARTNER_CENTER"] = "partnercenter";
    PLATFORM["ADMIN_PANEL"] = "adminpanel";
    PLATFORM["DEVELOPMENT"] = "development";
    PLATFORM["DISABLED"] = "disabled";
})(PLATFORM || (PLATFORM = {}));
export var MENU_GROUP;
(function (MENU_GROUP) {
    MENU_GROUP["DASHBOARD"] = "dashboard";
    MENU_GROUP["CONTENT_MANAGEMENT"] = "contentmanagement";
    MENU_GROUP["SUPER_ADMIN"] = "superadmin";
    MENU_GROUP["USER_MANAGEMENT"] = "userManagement";
    MENU_GROUP["FOOD_SERVICES"] = "foodservices";
    MENU_GROUP["QUICK_COMMERCE"] = "quickcommerce";
    MENU_GROUP["PROFILE"] = "profile";
    MENU_GROUP["HELP"] = "help";
})(MENU_GROUP || (MENU_GROUP = {}));
export var LOCALSTORAGE;
(function (LOCALSTORAGE) {
    LOCALSTORAGE["LANDING_PAGE"] = "landing_page";
})(LOCALSTORAGE || (LOCALSTORAGE = {}));
export var LOCAL_TIMEZONE = "+0800";
export var TODAY = moment()
    .utcOffset(LOCAL_TIMEZONE)
    .startOf("day");
export var YESTERDAY = moment()
    .utcOffset(LOCAL_TIMEZONE)
    .add(-1, "day")
    .startOf("day");
export var SIDE_NAVBAR_FULL_WIDTH = "105px";
export var ALPHANUMERIC_CHARSET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export var NUMERIC_CHARSET = "0123456789";
