import { useEffect } from "react";
import { usePlatform, useUserInfo } from "../../../context/hooks";
import { setupGAConfig } from "../googleanalytics";
export var useUserGASetup = function () {
    var _a = useUserInfo(), user_type = _a.user_type, checked = _a.checked;
    var platform = usePlatform();
    useEffect(function () {
        if (!checked)
            return;
        setupGAConfig({
            user_type: user_type,
            platform: platform,
        });
    }, [checked, user_type, platform]);
};
