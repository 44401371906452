export var GA_COMMAND;
(function (GA_COMMAND) {
    GA_COMMAND["EVENT"] = "event";
    GA_COMMAND["GET"] = "get";
    GA_COMMAND["SET"] = "set";
    GA_COMMAND["CONFIG"] = "config";
    GA_COMMAND["CONSENT"] = "consent";
})(GA_COMMAND || (GA_COMMAND = {}));
export var GA_EVENT;
(function (GA_EVENT) {
    GA_EVENT["PAGE_VIEW"] = "page_view";
    GA_EVENT["LOGIN"] = "login";
})(GA_EVENT || (GA_EVENT = {}));
