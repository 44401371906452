import { useCallback, useEffect, useRef, useState } from "react";
import { globalEvents } from "../GlobalEvents";
import { getCurrentTime } from "../helpers";
export var useEventTriggeredTimeUpdate = function (event, maxCalls) {
    if (maxCalls === void 0) { maxCalls = 0; }
    var called = useRef(0);
    var _a = useState(getCurrentTime().valueOf()), time = _a[0], setTime = _a[1];
    var updateTime = useCallback(function () {
        if (maxCalls > 0 && called.current === maxCalls)
            return;
        called.current++;
        setTime(getCurrentTime().valueOf());
    }, [maxCalls]);
    useEffect(function () {
        if (!event)
            return;
        globalEvents.on(event, updateTime);
        return function () {
            globalEvents.off(event, updateTime);
        };
    }, [event]);
    return time;
};
