var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useEffect } from "react";
import { globalEvents } from "../../../common/GlobalEvents";
import { AllContext } from "../../../components/context/constants";
import { getURLParams } from "../../../components/routing/helpers";
import { useGoSPARouteWithQueryString, useGoToLastLandingPage, } from "../../../components/routing/hooks";
import { TRACKING_EVENT } from "../../../components/tracking/constants";
import { AUTH_PATH } from "../constants";
import { getPermissionsFromJWTToken } from "./helpers";
export var useGoToLoginWhenNotLoginFlow = function () {
    var goToLogin = useGoSPARouteWithQueryString(AUTH_PATH.LOGIN_PAGE);
    useEffect(function () {
        var code = getURLParams().code;
        if (!code) {
            goToLogin();
        }
    }, []);
};
export var useAuthLoginFlow = function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, user = _a.user, isLoading = _a.isLoading, getAccessTokenSilently = _a.getAccessTokenSilently, getIdTokenClaims = _a.getIdTokenClaims;
    var _b = useContext(AllContext.GeneralDataContext), platform = _b[0].platform, setGeneralContext = _b[1];
    var resumePath = useGoToLastLandingPage();
    var getAccessToken = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, idToken, permissions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccessTokenSilently()];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, getIdTokenClaims()];
                case 2:
                    idToken = _a.sent();
                    permissions = getPermissionsFromJWTToken(accessToken);
                    setGeneralContext(function (prev) {
                        var _a, _b, _c, _d, _e;
                        return (__assign(__assign({}, prev), { userInfo: __assign(__assign({}, prev.userInfo), { name: (_a = user === null || user === void 0 ? void 0 : user.name) !== null && _a !== void 0 ? _a : "", email: (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : "" }), auth0: {
                                id: (_c = user === null || user === void 0 ? void 0 : user.sub) !== null && _c !== void 0 ? _c : "",
                                picture: (_d = user === null || user === void 0 ? void 0 : user.picture) !== null && _d !== void 0 ? _d : "",
                                accessToken: accessToken,
                                idToken: (_e = idToken === null || idToken === void 0 ? void 0 : idToken.__raw) !== null && _e !== void 0 ? _e : "",
                            }, permissions: permissions, isLoggedIn: true }));
                    });
                    globalEvents.emit(TRACKING_EVENT.GA_LOGIN_EVENT, { platform: platform, permissions: permissions });
                    resumePath();
                    return [2 /*return*/];
            }
        });
    }); }, [getAccessTokenSilently, user, resumePath, platform, getIdTokenClaims]);
    useEffect(function () {
        if (isLoading)
            return;
        if (!isAuthenticated)
            return;
        getAccessToken();
    }, [isAuthenticated, isLoading, getAccessToken]);
    return {
        getAccessToken: getAccessToken,
    };
};
