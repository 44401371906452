import { useEffect } from "react";
import { globalEvents } from "../../common/GlobalEvents";
import { TRACKING_EVENT } from "./constants";
import { trackGARouteEvent, trackLoginEvent } from "./ga/googleanalytics";
export var useEventTracking = function () {
    useEffect(function () {
        globalEvents.on(TRACKING_EVENT.GA_ROUTE_EVENT, trackGARouteEvent);
        globalEvents.on(TRACKING_EVENT.GA_LOGIN_EVENT, trackLoginEvent);
        return function () {
            globalEvents.off(TRACKING_EVENT.GA_ROUTE_EVENT, trackGARouteEvent);
            globalEvents.off(TRACKING_EVENT.GA_LOGIN_EVENT, trackLoginEvent);
        };
    }, []);
};
