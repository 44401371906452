import { lazy } from "react";
import { MENU_GROUP, PLATFORM } from "../../../common/constants";
import { globalEvents } from "../../../common/GlobalEvents";
import { PERMISSION } from "../../../components/rbac/permissions";
import { ROUTE_EVENTS } from "../../../components/routing/constants";
import { FPGEVOUCHER_PATH } from "./constants";
/* istanbul ignore next */ // no need to test lazy loading
var LazyComponents = {
    FPGEVouchersList: lazy(function () {
        return import(
        /* webpackChunkName: "fs-fpgevoucherlist" */ "./fpgevoucherslist/FPGEVouchersList");
    }),
    FPGEVoucherAdd: lazy(function () {
        return import(
        /* webpackChunkName: "fs-fpgevoucheradd" */ "./fpgevoucheradd/FPGEVoucherAdd");
    }),
    FpgevoucherDetail: lazy(function () {
        return import(
        /* webpackChunkName: "fs-fpgevoucherdetail" */ "./fpgevoucherdetail/FpgevoucherDetail");
    }),
    // anchor - for code generator
};
var PLATFORM_ACCESS = [PLATFORM.ADMIN_PANEL];
/* istanbul ignore next */ // no need to test routes
export var FPGEVoucherRoutes = [
    {
        label: "fpgevoucher.menu.label",
        route: FPGEVOUCHER_PATH.FPGEVOUCHER_LIST,
        component: LazyComponents.FPGEVouchersList,
        platforms: PLATFORM_ACCESS,
        group: MENU_GROUP.FOOD_SERVICES,
        permissions: [PERMISSION.FS_FPGEVOUCHER_VIEW],
    },
    {
        label: "fpgevoucher.menu.add.label",
        hidden: true,
        route: FPGEVOUCHER_PATH.FPGEVOUCHER_ADD,
        component: LazyComponents.FPGEVoucherAdd,
        permissions: [
            PERMISSION.FS_FPGEVOUCHER_CREATE,
            PERMISSION.FS_FPGEVOUCHER_EDIT,
            PERMISSION.FS_FPGEVOUCHER_VIEW,
            PERMISSION.FS_FPGEVOUCHER_DELETE,
        ],
        platforms: [],
    },
    {
        label: "",
        hidden: true,
        route: FPGEVOUCHER_PATH.FPGEVOUCHER_DETAIL,
        component: LazyComponents.FpgevoucherDetail,
        permissions: [PERMISSION.FS_FPGEVOUCHER_VIEW],
        platforms: [],
    },
    // anchor - for code generator
];
export var initFPGEVoucher = function () {
    globalEvents.emit(ROUTE_EVENTS.REGISTER, FPGEVoucherRoutes);
};
